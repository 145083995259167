import React, { useState } from "react";
import "../../assets/css/Home.css";
import avatar from "../../assets/images/avatar.svg";
import banner from "../../assets/images/banner_img.svg";
import { Link } from "react-router-dom";
import project_img_1 from "../../assets/images/dummy_project_1.png";
import project_img_2 from "../../assets/images/dummy_project_2.png";
import project_img_3 from "../../assets/images/dummy_project_3.png";
import project_img_4 from "../../assets/images/dummy_project_4.png";
import project_img_5 from "../../assets/images/dummy_project_5.png";
import project_img_6 from "../../assets/images/dummy_project_6.png";
import { FaHandPeace } from "react-icons/fa6";
import { IoChatboxEllipsesOutline } from "react-icons/io5";
import testimonial_img_1 from "../../assets/images/testimonial_avatar_1.png";
import testimonial_img_2 from "../../assets/images/testimonial_avatar_2.png";
import { FaLaptopCode, FaLightbulb, FaMobileAlt } from "react-icons/fa";

const Home = () => {
  const testimonials = [
    {
      img: testimonial_img_1,
      statement:
        "Ankit was a real pleasure to work with and we look forward to working with him again. He's definitely the kind of developer",
      clientName: "Pasca Tremblay",
      designation: "Creative lead, GoodKind",
    },
    {
      img: testimonial_img_2,
      statement:
        "Ankit's a clear communicator with the tenacity and confidence to really dig in to tricky dev scenarios and the collaborative friction that's needed to produce excellent work",
      clientName: "Alvin Engler",
      designation: "CEO, West Third Enterprises Inc.",
    },
  ];

  const handleDotClick = (index) => {
    setCurrentIndex(index);
  };

  const [currentIndex, setCurrentIndex] = useState(0);
  return (
    <>
      <div className="home_wrapper">
        <div className="banner_section" data-aos="fade-down">
          <div className="banner_tagline">
            <h1>Frontend Developer</h1>
            <p>I code beautifully simple things, and I love what I do.</p>
          </div>
          <div className="avatar_container">
            <img src={avatar} alt="" />
          </div>
          <div className="banner_image_container">
            <img src={banner} alt="" />
          </div>
        </div>
        <div className="intro_section flex justify-center">
          <div className="intro_text_wrapper">
            <div className="title">
              <h1>Hi, I am Ankit Lakhlan, Nice to see you.</h1>
            </div>
            <div className="intro_text">
              <p>
                Since beginning my journey as a freelance developer 2 years ago,
                I've done remote work for agencies, consulted for startups, and
                collaborated with talented people to create digital products for
                both business and consumer use. I'm quietly confident, naturally
                curious, and perpetually working on improving my chops.
              </p>
            </div>
          </div>
        </div>

        <div className="skills_section">
          <div className="container">
            <div className="skills_wrapper">
              {/* <div className="skills_item_container">
                <div className="skills_item">
                  <div className="flex flex-center justify-center">
                    <div className="icon_container">
                      <FaMobileAlt className="icon" />
                    </div>
                  </div>
                  <div className="intro">
                    <h1>Android Development Services</h1>
                    <p>
                      Our team of skilled Android developers creates
                      high-performance, custom apps that bring your ideas to
                      life.
                    </p>
                  </div>
                  <div className="tech_stack">
                    <div className="tech_title">
                      <p>Technologies We Use</p>
                    </div>
                    <div className="tech_listing">
                      <p>Kotlin, Java, Flutter, React Native</p>
                    </div>
                    <div className="tech_title">
                      <p>Development Tools</p>
                    </div>
                    <div className="tech_listing">
                      <li>Android Studio</li>
                      <li>Git</li>
                      <li>Jira</li>
                      <li>Postman</li>
                    </div>
                  </div>
                </div>
                <div className="skills_item">
                  <div className="flex flex-center justify-center">
                    <div className="icon_container">
                      <FaLightbulb className="icon" />
                    </div>
                  </div>
                  <div className="intro">
                    <h1>Consultancy Services</h1>
                    <p>
                      We provide expert consulting to help businesses streamline
                      their digital strategies, enhance their tech
                      solutions.
                    </p>
                  </div>
                  <div className="tech_stack">
                    <div className="tech_title">
                      <p>Areas We Consult In</p>
                    </div>
                    <div className="tech_listing">
                      <p>
                        Tech Stack Evaluation, Process Automation
                      </p>
                    </div>
                    <div className="tech_title">
                      <p>Consulting Tools</p>
                    </div>
                    <div className="tech_listing">
                      <li>Microsoft Teams</li>
                      <li>Slack</li>
                      <li>Google Workspace</li>
                      <li>Trello</li>
                    </div>
                  </div>
                </div>
                <div className="skills_item">
                  <div className="flex flex-center justify-center">
                    <div className="icon_container">
                      <FaLaptopCode className="icon" />
                    </div>
                  </div>
                  <div className="intro">
                    <h1>Web Development Services</h1>
                    <p>
                      Our experienced web development team crafts responsive,
                      modern websites to elevate your online presence.
                    </p>
                  </div>
                  <div className="tech_stack">
                    <div className="tech_title">
                      <p>Technologies We Use</p>
                    </div>
                    <div className="tech_listing">
                      <p>HTML5, CSS3, JavaScript, React, Node.js</p>
                    </div>
                    <div className="tech_title">
                      <p>Development Tools</p>
                    </div>
                    <div className="tech_listing">
                      <li>Visual Studio Code</li>
                      <li>Git</li>
                      <li>GitHub</li>
                      <li>Figma</li>
                    </div>
                  </div>
                </div>
              </div> */}
              <div className="skills_item_container">
                <div className="skills_item">
                  <div className="flex flex-center justify-center">
                    <div className="icon_container">
                      <FaLaptopCode className="icon" />
                    </div>
                  </div>
                  <div className="intro">
                    <h1>Frontend Developer</h1>
                    <p>
                      I am a frontend developer with a versatile team and
                      expertise in delivering solutions across various
                      platforms, including Android development, consultancy, and
                      web development.
                    </p>
                  </div>

                  <div className="service_section">
                    {/* Android Development Services */}
                    <div className="service_item">
                      <div className="flex flex-center justify-center">
                        <div className="icon_container">
                          <FaMobileAlt className="icon" />
                        </div>
                      </div>
                      <div className="intro">
                        <h2>Android Development Services</h2>
                        <p>
                          Our team of skilled Android developers creates
                          high-performance, custom apps that bring your ideas to
                          life.
                        </p>
                      </div>
                      <div className="tech_stack">
                        <div className="tech_title">
                          <p>Technologies We Use</p>
                        </div>
                        <div className="tech_listing">
                          <p>Kotlin, Java, Flutter, React Native</p>
                        </div>
                        <div className="tech_title">
                          <p>Development Tools</p>
                        </div>
                        <div className="tech_listing">
                          <li>Android Studio</li>
                          <li>Git</li>
                          <li>Jira</li>
                          <li>Postman</li>
                        </div>
                      </div>
                    </div>

                    {/* Consultancy Services */}
                    <div className="service_item">
                      <div className="flex flex-center justify-center">
                        <div className="icon_container">
                          <FaLightbulb className="icon" />
                        </div>
                      </div>
                      <div className="intro">
                        <h2>Consultancy Services</h2>
                        <p>
                          We provide expert consulting to help businesses
                          streamline their digital strategies and enhance their
                          tech solutions.
                        </p>
                      </div>
                      <div className="tech_stack">
                        <div className="tech_title">
                          <p>Areas We Consult In</p>
                        </div>
                        <div className="tech_listing">
                          <p>Tech Stack Evaluation, Process Automation</p>
                        </div>
                        <div className="tech_title">
                          <p>Consulting Tools</p>
                        </div>
                        <div className="tech_listing">
                          <li>Microsoft Teams</li>
                          <li>Slack</li>
                          <li>Google Workspace</li>
                          <li>Trello</li>
                        </div>
                      </div>
                    </div>

                    {/* Web Development Services */}
                    <div className="service_item">
                      <div className="flex flex-center justify-center">
                        <div className="icon_container">
                          <FaLaptopCode className="icon" />
                        </div>
                      </div>
                      <div className="intro">
                        <h2>Web Development Services</h2>
                        <p>
                          Our experienced web development team crafts
                          responsive, modern websites to elevate your online
                          presence.
                        </p>
                      </div>
                      <div className="tech_stack">
                        <div className="tech_title">
                          <p>Technologies We Use</p>
                        </div>
                        <div className="tech_listing">
                          <p>HTML5, CSS3, JavaScript, React, Node.js</p>
                        </div>
                        <div className="tech_title">
                          <p>Development Tools</p>
                        </div>
                        <div className="tech_listing">
                          <li>Visual Studio Code</li>
                          <li>Git</li>
                          <li>GitHub</li>
                          <li>Figma</li>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="projects">
          <div className="section_title">
            <h1>My Work</h1>
            <p>
              Here are a few of my recent projects I've worked on. Want some
              more? <Link>Email Me</Link>
            </p>
          </div>
          <div className="projects_rows_container">
            <div className="projects_row">
              <div className="projects_item">
                <figure>
                  <img src={project_img_1} alt="" className="project_thumb" />
                  <figcaption>
                    <h1>
                      Developed a responsive website for an NBFC, enhancing user
                      experience and functionality.
                    </h1>
                    <Link to="https://salaryontime.com">Visit Website</Link>
                  </figcaption>
                  <div className="overlay"></div>
                </figure>
              </div>
              <div className="projects_item">
                <figure>
                  <img src={project_img_2} alt="" className="project_thumb" />
                  <figcaption>
                    <h1>
                      Crafted a visually appealing portfolio website to showcase
                      the client’s skills and achievements
                    </h1>
                    <Link to="https://www.harshilgodara.com">
                      Visit Website
                    </Link>
                  </figcaption>
                  <div className="overlay"></div>
                </figure>
              </div>
              <div className="projects_item">
                <figure>
                  <img src={project_img_3} alt="" className="project_thumb" />
                  <figcaption>
                    <h1>
                      Developed a modern website for an ISP, streamlining
                      service information.
                    </h1>
                    <Link to="https://www.absenterprises.co.in">
                      Visit Website
                    </Link>
                  </figcaption>
                  <div className="overlay"></div>
                </figure>
              </div>
            </div>
            <div className="projects_row">
              <div className="projects_item">
                <figure>
                  <img src={project_img_4} alt="" className="project_thumb" />
                  <figcaption>
                    <h1>
                      Developed a responsive and user friendly website for a
                      payment gateway providing company.
                    </h1>
                    <Link to="https://payment-gateway-website-eight.vercel.app/">Visit Website</Link>
                  </figcaption>
                  <div className="overlay"></div>
                </figure>
              </div>
              <div className="projects_item">
                <figure>
                  <img src={project_img_5} alt="" className="project_thumb" />
                  <figcaption>
                    <h1>
                      Vancouver's tower crane rental service and support leader
                      since 1974.
                    </h1>
                    <Link to="">Visit Website</Link>
                  </figcaption>
                  <div className="overlay"></div>
                </figure>
              </div>
              <div className="projects_item">
                <figure>
                  <img src={project_img_6} alt="" className="project_thumb" />
                  <figcaption>
                    <h1>
                      Results-oriented, responsive and flexible full service
                      civil engineering firm.
                    </h1>
                    <Link to="">Visit Website</Link>
                  </figcaption>
                  <div className="overlay"></div>
                </figure>
              </div>
            </div>
          </div>
        </div>

        <div className="collaboration_section">
          <div className="section_title">
            <h1>Interested in collaborating with me?</h1>
            <p>
              I'm always open to discussing dev work or partnership
              opportunities.
            </p>
          </div>
          <div className="flex justify-center flex-center">
            <Link to="startup_enquiry">
              <span>
                <IoChatboxEllipsesOutline className="chat_icon" />
              </span>
              <span>Start a conversation</span>
            </Link>
          </div>
        </div>

        <div className="testimonials_section">
          <div className="section_title">
            <h1>Testimonials</h1>
            <p>People I've worked with have said some nice things</p>
          </div>

          <div className="testimonial_slider">
            {/* Wrapper for the sliding effect */}
            <div
              className="testimonial_wrapper"
              style={{ transform: `translateX(-${currentIndex * 100}%)` }}
            >
              {testimonials.map((testimonial, index) => (
                <div className="testimonial_item" key={index}>
                  <div className="avatar">
                    <img src={testimonial.img} alt="" />
                  </div>
                  <div className="statement">
                    <p>{testimonial.statement}</p>
                  </div>
                  <div className="description">
                    <p className="client_name">{testimonial.clientName}</p>
                    <span className="designation">
                      {testimonial.designation}
                    </span>
                  </div>
                </div>
              ))}
            </div>

            {/* Dot navigation */}
            <div className="dots">
              {testimonials.map((_, index) => (
                <span
                  key={index}
                  className={`dot ${currentIndex === index ? "active" : ""}`}
                  onClick={() => handleDotClick(index)}
                ></span>
              ))}
            </div>
          </div>
        </div>

        <div className="calltoaction_section">
          <div className="container">
            <div className="box">
              <div className="row">
                <div className="item">
                  <h1>Start a project</h1>
                </div>
                <div className="item">
                  <p>
                    Interested in working together? We should queue up a time to
                    chat. I'll buy the coffee.
                  </p>
                </div>
                <div className="item">
                  <Link to="project_discussion">
                    <FaHandPeace className="hand_icon" />
                    <span>Let's do this</span>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Home;
